/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getInfo as t } from "../../../geometry/support/spatialReferenceUtils.js";
import r from "./TileKey.js";
function o(t, r) {
  return [t, r];
}
function i(t, r, o) {
  return t[0] = r, t[1] = o, t;
}
function e(t, r, o, i, e) {
  return t[0] = r, t[1] = o, t[2] = i, t[3] = e, t;
}
const s = new r("0/0/0/0");
class n {
  static create(r, e, s = null) {
    const l = t(r.spatialReference),
      h = e.origin || o(r.origin.x, r.origin.y),
      a = o(r.size[0] * e.resolution, r.size[1] * e.resolution),
      u = o(-1 / 0, -1 / 0),
      m = o(1 / 0, 1 / 0),
      g = o(1 / 0, 1 / 0);
    null != s && (i(u, Math.max(0, Math.floor((s.xmin - h[0]) / a[0])), Math.max(0, Math.floor((h[1] - s.ymax) / a[1]))), i(m, Math.max(0, Math.floor((s.xmax - h[0]) / a[0])), Math.max(0, Math.floor((h[1] - s.ymin) / a[1]))), i(g, m[0] - u[0] + 1, m[1] - u[1] + 1));
    const {
      cols: w,
      rows: c
    } = e;
    let d, f, F, z;
    return !s && w && c && (i(u, w[0], c[0]), i(m, w[1], c[1]), i(g, w[1] - w[0] + 1, c[1] - c[0] + 1)), r.isWrappable ? (d = o(Math.ceil(Math.round((l.valid[1] - l.valid[0]) / e.resolution) / r.size[0]), g[1]), f = o(Math.floor((l.origin[0] - h[0]) / a[0]), u[1]), F = o(d[0] + f[0] - 1, m[1]), z = !0) : (f = u, F = m, d = g, z = !1), new n(e.level, e.resolution, e.scale, h, u, m, g, a, f, F, d, z);
  }
  constructor(t, r, o, i, e, s, n, l, h, a, u, m) {
    this.level = t, this.resolution = r, this.scale = o, this.origin = i, this.first = e, this.last = s, this.size = n, this.norm = l, this.worldStart = h, this.worldEnd = a, this.worldSize = u, this.wrap = m;
  }
  normalizeCol(t) {
    if (!this.wrap) return t;
    const r = this.worldSize[0];
    return t < 0 ? r - 1 - Math.abs((t + 1) % r) : t % r;
  }
  denormalizeCol(t, r) {
    return this.wrap ? this.worldSize[0] * r + t : t;
  }
  getWorldForColumn(t) {
    return this.wrap ? Math.floor(t / this.worldSize[0]) : 0;
  }
  getFirstColumnForWorld(t) {
    return t * this.worldSize[0] + this.first[0];
  }
  getLastColumnForWorld(t) {
    return t * this.worldSize[0] + this.first[0] + this.size[0] - 1;
  }
  getColumnForX(t) {
    return (t - this.origin[0]) / this.norm[0];
  }
  getXForColumn(t) {
    return this.origin[0] + t * this.norm[0];
  }
  getRowForY(t) {
    return (this.origin[1] - t) / this.norm[1];
  }
  getYForRow(t) {
    return this.origin[1] - t * this.norm[1];
  }
  getTileBounds(t, r, o = !1) {
    s.set(r);
    const i = o ? s.col : this.denormalizeCol(s.col, s.world),
      n = s.row;
    return e(t, this.getXForColumn(i), this.getYForRow(n + 1), this.getXForColumn(i + 1), this.getYForRow(n)), t;
  }
  getTileCoords(t, r, o = !1) {
    s.set(r);
    const e = o ? s.col : this.denormalizeCol(s.col, s.world);
    return Array.isArray(t) ? i(t, this.getXForColumn(e), this.getYForRow(s.row)) : (t.x = this.getXForColumn(e), t.y = this.getYForRow(s.row)), t;
  }
}
export { n as default };